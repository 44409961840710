.uploader {
    #photo-or-vid {
        font-weight: 500;
    }

    .upload-photo {
        width: 80%;
        height: 165px;
        background-color: #ebebeb;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;
        margin: auto;
        margin-top: 10px;
        img {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
        video {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .retry-button {
        background-color: #00aeef;
        color: #ffffff;
        width: 150px;
        height: 50px;
        padding: 10px;
        font-size: 18px;
        margin: auto;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }

    .file-name {
        font-size: 11px;
        max-width: 30%;
    }

    input {
        background-color: #ebebeb;
        border: none;
        outline: none;
    }

    .switching-through-text {
        padding-right: 50px;
        padding-left: 50px;
    }

    textarea {
        resize: none;
        background-color: #f3f3f3;
        border: none;
        outline: none;
        width: 80%;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 1em;
    }

    textarea::placeholder {
        text-align: center;
        font-size: 16px;
    }

    .prompt {
        width: 80%;
        background-color: #00aeef;
        border-radius: 15px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        margin: auto;
        padding: 10px;
    }

    .upload-recording {
        width: 125px;
        height: 125px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;
        background-color: #ffffff;
        border: none;
        margin: auto;
    }

    .trash {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        cursor: pointer;
        border: none;
        margin: auto;
    }

    .submit-button {
        border-radius: 10px;
        width: 160px;
        font-size: 18px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: auto auto 2em;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        user-select: none;
    }

    .redo-button {
        border-radius: 10px;
        width: 35%;
        font-size: 20px;
        background-color: #00aeef;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: auto auto 5px;
    }

    .checkbox {
        display: flex;
        margin-top: 30px;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        accent-color: #ec1f3a;

        label {
            cursor: pointer;
        }
    }

    .name {
        text-align: center;
    }

    .name-sentence {
        font-size: 14px;
    }

    #name {
        width: 80%;
        font-size: 16px;
        margin: 10px 0px;
        padding: 1em;
    }

    [disabled] {
        background-color: gray;
        cursor: not-allowed;
    }

    ::placeholder {
        font-style: italic;
    }

    .go-header {
        font-size: 16px;
        font-weight: 700;
    }

    .pano-bg {
        height: 170px;
        width: 100%;
        background-color: #d2f1fa;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        margin-top: 160px;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 500;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.7);
        align-items: center;
        justify-content: center;
    }
    .arrow-and-topic {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding-right: 10px;
        vertical-align: center;
        align-items: center;
        user-select: none;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        p {
            margin: 0;
        }
    }

    textarea {
        margin-top: 25px;
        .write {
            height: 400px !important;
        }
    }

    .hidden {
        display: none;
    }

    .audioPlayerIcons {
        min-height: 125px;
        min-width: 125px;
    }
}

.create-placeholder {
    margin: 1em 5%;
    border: 1px solid #ec1f3a;
    padding: 0 1em;
    text-align: left;
    p {
        line-height: 1.5;
        font-style: italic;
    }
}
