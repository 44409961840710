.write-uploader {
    .upload-optional-photo-button {
        width: 80%;
        height: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;
        margin: auto;
        margin-bottom: 15px;
        border: 1px #00aaef solid;
        margin-top: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        user-select: none;
    }

    img {
        object-fit: contain;
        max-width: 70%;
        max-height: 50%;
    }

    .file-name {
        font-size: 11px;
        max-width: 30%;
    }

    input {
        background-color: #ebebeb;
        border: none;
        outline: none;
    }

    .prompt {
        width: 80%;
        background-color: #00aeef;
        border-radius: 10px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        margin: auto;
        padding: 10px;
    }

    textarea {
        resize: none;
        background-color: #f3f3f3;
        border: none;
        outline: none;
        width: 80%;
        height: 40px;
        margin-bottom: 10px;
        padding: 1em;
        font-size: 16px;
    }

    textarea::placeholder {
        text-align: center;
        font-size: 14px;
    }

    .submit-button {
        border-radius: 10px;
        width: 160px;
        font-size: 18px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: auto auto 2em;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .checkbox {
        margin-top: 30px;
        display: flex;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        accent-color: #ec1f3a;
    }

    .checkbox checkbox {
        background-color: grey;
        padding-top: 15px;
    }

    #write {
        width: 80%;
        height: 300px;
        padding: 1em;
        font-size: 16px;
        margin: 10px 0px;
        border-radius: 10px;
    }

    .arrow-and-topic {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding-right: 10px;
        vertical-align: center;
        align-items: center;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 500;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.7);
        align-items: center;
        justify-content: center;
    }
    .name {
        text-align: center;
    }

    .name-sentence {
        font-size: 14px;
    }

    [disabled] {
        background-color: gray;
        cursor: not-allowed;
    }

    #name {
        width: 80%;
        padding: 1em;
        font-size: 16px;
        margin-top: 25px;
    }

    ::placeholder {
        font-style: italic;
    }
}
