.app-header {
    background-color: #f5f5f5;
    padding: 0 1em;
    user-select: none;

    h1 {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
    }

    img {
        display: block;
        transform: scale(1.1);
        box-shadow: 0 4px 2px rgba(0, 0, 0, 0.15);
        height: 50px;
        margin-right: 1em;
        user-select: none;
    }
    a {
        text-decoration: none;
        color: black;
        user-select: none;
    }
}
