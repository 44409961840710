.submit-button-page {
    .submit-button {
        background-color: #ec1f3a;
        user-select: none;
        color: #ffffff;
        font-size: 22px;
        display: flex;
        border-radius: 15px;
        margin: auto auto 20px;
        padding: 15px;
        width: 70%;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .submit-button-data {
        display: flex;
        margin: auto;
    }
    h3 {
        max-width: 80%;
        margin: 0 auto 30px;
    }

    .submit-button-data img {
        padding-right: 15px;
    }
    .submit-button-data strong {
        margin: auto;
        user-select: none;
    }

    .submit-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 5vh;
    }

    a:link {
        text-decoration: none;
    }
}
