.app-footer {
    background-color: #f3f3f3;
    user-select: none;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 10px solid #d2f1fa;
    //bottom: 0;
    //position: fixed;

    a {
        padding: 15px; //expands hitbox for easier tapping of icons
        display: block;
    }

    svg {
        height: 35px;
        display: block;
        * {
            transition: fill 0.3s;
        }
    }

    .active {
        svg {
            * {
                fill: #00aeef;
            }
        }
    }
}

.dynamic-footer-buttons {
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}

.icons {
    width: 20%;
    background-color: transparent;
}

.dynamic-positioning {
    position: relative;
    bottom: 0;
    background-color: transparent;
    padding-bottom: 13px;
}

.background-light-blue-highlight {
    background-color: #d2f1fa;
    width: 100%;
    height: 40px;
    border-radius: 8px 0 0 8px;
}
.about-button {
    position: relative;
    height: 50%;
    width: 50%;
    bottom: 12px;
}
