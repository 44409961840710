.topic {
    display: flex;
    align-items: center;
    flex-direction: column;

    video {
        width: 90%;
        display: block;
        margin: auto;
        margin-bottom: 50px;
    }
    .uploader-description-text {
        font-size: 16px;
    }
    a {
        text-decoration: none;
        color: black;
        text-align: left;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        user-select: none;
    }
    strong {
        font-size: 28px;
        user-select: none;
    }
    p {
        margin: 0;
        line-height: 1.3;
        font-size: 16px;
    }
    svg {
        * {
            fill: white;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 2em auto;
        max-width: 90vw;
    }

    li + li {
        margin-top: 1em;
    }

    .icon {
        background: #eb1f3a;
        color: white;
        font-size: 32px;
        width: 282px;
        display: flex;
        padding: 10px 10px 10px 50px;
        border-radius: 15px;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    svg {
        height: 40px;
        width: 40px;
        margin-right: 0.35em;
    }

    .intro-paragraph {
        text-align: left;
        width: 90%;
        font-size: 1em;
        line-height: 1.5;
    }

    #link-label {
        font-weight: bold;
        font-size: 15px;
        text-align: center;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 10000000000;
        display: flex;
        justify-content: center;
        align-items: center;

        .overlay-content {
            background: #d2f1fa;
            border-radius: 15px;
            width: 90%;
            max-width: 600px;
            max-height: 90%;
            overflow: auto;
        }

        .overlay-header {
            background: #eb1f3a;
            border-radius: 15px 15px 0 0;
            position: relative;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1em;
            strong {
                font-size: 36px;
            }
            svg {
                height: 40px;
                width: 40px;
                margin-right: 0.5em;
            }
        }

        .overlay-close {
            position: absolute;
            top: 0;
            left: 0;
            font-weight: bold;
            border: none;
            background: none;
            font-size: 36px;
            color: white;
            height: 100%;
            width: 1.5em;
            z-index: 100;
            margin: 0;
        }

        .overlay-instructions {
            padding: 2em 2em 3em;

            img {
                width: 100%;
                margin-bottom: 1em;
            }

            p {
                font-weight: 600;
            }
        }

        a {
            background: #eb1f3a;
            color: white;
            text-decoration: none;
            padding: 0.5em 1em;
            font-weight: bold;
            width: max-content;
            border-radius: 15px;
            margin: 1em auto 0;
            font-size: 21px;
        }
    }
}

.responsive-embed {
    position: relative;
    height: 0;
    width: 90%;
    padding-bottom: 56.25%;
    margin: 2em auto;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        aspect-ratio: 16 / 9;
        z-index: 1;
    }
}
