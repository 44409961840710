.go {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;

    .go-header {
        font-size: 18px;
        font-weight: 700;
        margin-top: 15px;
    }

    .pano-bg {
        height: 40%;
        width: 100%;
        background-color: #d2f1fa;
        font-weight: bold;
        display: flex;
        position: relative;
        margin-top: -10px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        font-size: 18px;
    }

    .here-button {
        border-radius: 10px;
        width: 160px;
        font-size: 18px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        margin: 2em 0px;
        cursor: pointer;
        font-weight: 700;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .arrive {
        color: #00aaef;
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .picture {
        position: relative;
        width: 90%;
        z-index: 5;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
        height: 200px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 10px;
    }
    .directions-link {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 200px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}
