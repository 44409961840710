.thank-you-modal {
    width: 80%;
    background-color: white;
    box-shadow: 0 5px 3px grey;
    border-radius: 20px;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 30px;
}

.modal-return-button {
    border-radius: 10px;
    width: 160px;
    font-size: 18px;
    height: 35px;
    background-color: #ec1f3a;
    color: #ffffff;
    border: none;
    cursor: pointer;
    padding: 8px 10px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    top: 1%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 10px;
    user-select: none;
}

.modal-little-text {
    font-size: 12px;
    font-weight: normal;
    margin: 5% 18%;
}

.modal-image {
    width: 100%;
    max-width: 150px !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.modal-exit-button {
    width: 3%;
    cursor: pointer;
    padding: 5% 0 0 7%;
    text-align: left;
    font-weight: bold;
    font-size: 28px;
    user-select: none;
}

.modal-header {
    display: block;
    font-size: 2em;
    margin-top: 2%;
    margin-bottom: 5%;
    font-weight: bold;
}
