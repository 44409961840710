.about {
    .about-topics-buttons {
        background-color: #00aeef;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        width: 75%;
        border: none;
        margin: auto;
        margin-bottom: 10px;
        font-size: 18px;
        padding: 10px;
        border-radius: 10px;
        &:last-child {
            margin-bottom: 40px;
        }
    }

    video {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    div img {
        width: 82px;
    }
    div p {
        margin: 0px 10px;
    }
    .intro-text {
        padding: 10px;
    }
    .bottom-part {
        background-color: #f5f5f5;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        padding: 15px;
        margin: auto;
    }

    .topic-info-text {
        width: 72%;
        margin: auto;
        margin-top: 10px;
    }

    a:link {
        text-decoration: none;
    }

    .instructions {
        margin: 2em 0;
        line-height: 1.5;
        p {
            margin-bottom: 1em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
