.ContinueTheStory {
    height: 97%;
    overscroll-behavior: none;

    .prompt {
        color: black;
        padding: 3vw;
        text-align: center;
        margin: auto;
        flex-direction: column;
        display: flex;
        align-items: center;
        alignment: center;
        justify-content: center;
        z-index: 5;
    }

    #sampleStory {
        font-size: 17px;
        justify-content: space-around;
        line-height: 20px;
        align-items: flex-start;
        text-align: left;
    }

    .submit-button {
        border-radius: 10px;
        width: 180px;
        font-size: 19.5px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: auto auto 2em;
        padding: 8.5px 22px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    h4 {
        font-size: 1.2em;
        //margin-top: 15vh;
        margin-bottom: 1.8em;
        color: #00aeef;
    }

    .prompt img {
        height: 66px;
        width: 63px;
    }

    .promptsImage {
        margin: 8% auto 0 auto;
        width: 90%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    a {
        text-decoration: none;
        color: white;
    }

    a:link {
        text-decoration: none;
    }

    .audioImagesContainer {
        position: relative;
        margin: 8% auto 0 auto;
        padding-top: 20px;

        .backImg {
            width: 250px;
            height: 160px;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            z-index: 0;
        }

        .mediaControllerImg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            width: 65%;
            height: 65%;
            padding-top: 20px;
        }

        p {
            margin-bottom: 140px;
            font-family: "Montserrat", serif;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;
            text-align: center;
            letter-spacing: -0.015em;
            opacity: 0.5;
        }
    }

    video {
        margin: auto;
        width: 90%;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .text {
        max-width: 95%;
        padding-left: 6%;
        padding-bottom: 20px;
    }

    .prompts-with-image-text {
        transform: scale(0.7);
        margin-top: 0;
        top: 0;
    }
}
