.imhere {
    .go-header {
        font-size: 16px;
        font-weight: 700;
    }

    .audioImagesContainer {
        position: relative;
        margin: auto;
        width: 70%;
        z-index: 1;

        .backImg {
            object-fit: cover;
            border-radius: 15px;
            max-width: 100%;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            z-index: 0;
        }

        .mediaControllerImg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            cursor: pointer;
            z-index: 1;
        }
    }

    .arrive {
        color: #00aaef;
        font-weight: bold;
        font-size: 16px;
        top: 0;
        margin: 0 5% 1em;
        p {
            margin: 0;
            background: none;
            padding: 0;
            + p {
                margin-top: 1em;
            }
        }
    }

    audio {
        margin-bottom: 2em;
    }

    .submit-button {
        border-radius: 10px;
        width: 50%;
        font-size: 18px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        margin: 10px 0px;
        cursor: pointer;
        font-weight: 700;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    p {
        background-color: #f5f5f5;
        z-index: 0;
        position: relative;
        margin-top: -20px;
        line-height: 1.5;

        padding: 30px;
    }
}
