*,
*::before,
*::after {
    font-family: inherit;
    box-sizing: border-box;
}

html,
body {
    //height:-webkit-fill-available;
    //overflow-y: scroll;
    overscroll-behavior: none;
}

body {
    margin: 0;
    background: #e5e5e5;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
    min-height: 100vh;
    //height:100vh;
    //height:100dvh; /*new unit to fill full height of mobile*/
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

@media (min-width: 850px) {
    .App {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        overflow: hidden;
        min-height: 100vh;
        //height: 100vh;
        //height:100dvh; /*new unit to fill full height of mobile*/
        margin-top: 2.5vh;
        //margin-top:2.5dvh; /*new unit to fill full height of mobile*/
    }
}

.app-header {
    flex: 0 0 auto;
    width: 100%;
}

main {
    flex: 1 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding: 1em 0;
}

.app-footer {
    flex: 0 0 auto;
    width: 100%;
}
