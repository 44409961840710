.promptsPage {
    height: 97%;
    overscroll-behavior: none;

    .prompt {
        background-color: #00aeef;
        border-radius: 15px;
        color: white;
        padding: 3vw;
        text-align: center;
        width: min(81.7vw, 350px);
        height: min(48vh, 422px);
        margin: auto;
        flex-direction: column;
        display: flex;
        align-items: center;
        cursor: grab;
        font-size: 24px;
        alignment: center;
        justify-content: center;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
        z-index: 5;
    }

    #sampleStory {
        font-size: 15px;
        justify-content: space-around;
        line-height: 20px;
        align-items: flex-start;
        text-align: left;
    }

    .text-only {
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        padding: 20px 20px;
        max-width: 90%;
        max-height: 90%;
        margin: auto;

        p {
            margin: 0;
            padding: 0;
        }
    }

    .submit-button {
        border-radius: 10px;
        width: 180px;
        font-size: 19.5px;
        height: 35px;
        background-color: #ec1f3a;
        color: #ffffff;
        border: none;
        cursor: pointer;
        margin: auto auto 2em;
        padding: 8.5px 22px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    h4 {
        font-size: 1.2em;
        margin-top: 1.8em;
        margin-bottom: 1.8em;
        color: #00aeef;
    }

    .slider {
        margin-top: 1.8em;
        display: flex;
        align-items: center;
        position: relative;
    }

    .arrows {
        max-height: 30px;
        cursor: pointer;
        margin: auto;
        z-index: 5;
        user-select: none;
    }

    .background-color-box {
        z-index: 1;
        background-color: #d2f1fa;
        height: 75%;
        position: absolute;
        width: 100%;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .prompt:active {
        cursor: grabbing;
    }

    .prompt img {
        height: 66px;
        width: 63px;
    }

    .paging {
        display: flex;
        padding: 0px;
        justify-content: center;
        width: min(78vw, 350px);
        margin: 4% auto;
    }

    .paging li {
        list-style: none;
        background-color: #c4c4c4;
        height: 9px;
        flex: 1 1;
        margin: 5px;
    }

    .paging .active {
        background-color: #00aeef;
    }

    .promptsImage {
        margin: 8% auto 0 auto;
        width: 90%;
        height: 160px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .simplePrompt {
        background-color: #00aaef;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        margin: 0.5em 5%;
        width: 90%;

        a {
            padding: 0.5em 1em;
            font-size: 0.9rem;
            display: block;
        }
    }

    .promptList {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
    }

    a {
        text-decoration: none;
        color: white;
    }

    a:link {
        text-decoration: none;
    }

    .audioImagesContainer {
        position: relative;
        margin: 8% auto 0 auto;
        padding-top: 20px;

        .backImg {
            width: 250px;
            height: 160px;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            z-index: 0;
        }

        .mediaControllerImg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            width: 65%;
            height: 65%;
            padding-top: 20px;
        }

        p {
            margin-bottom: 140px;
            font-family: "Montserrat", serif;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;
            text-align: center;
            letter-spacing: -0.015em;
            color: #ffffff;
            opacity: 0.5;
        }
    }

    video {
        margin: auto;
        width: 90%;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .text {
        max-width: 90%;
        margin: auto;
    }

    .text p {
        padding: 0;
        margin: 0;
    }

    .create-heading {
        margin: 10px 20px 0 20px;
        font-size: 22px;
    }

    .talk-landing-header {
        font-size: 24px;
    }

    .prompts-with-image-text {
        transform: scale(0.7);
        margin-top: 0;
        top: 0;
    }

    .continue-the-story {
        font-weight: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin: 0 auto;

        img {
            width: 10%;
            margin-right: 5px;
        }
    }
    .storiesImage {
        margin: auto;
    }
    .storiesImage img {
        height: 150px;
        width: 100%;
        object-fit: contain;
    }
}
