.intro-video {
    max-width: 100vw;
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    margin-bottom: 2em;
}

.intro-video video {
    width: 100%;
    display: block;
    margin: 0;
}

.intro-video.paused::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 80px;
    border-color: transparent transparent transparent #ffffff;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}
